/* COLORS */
$white: white;
$gray: #ECF0F1;
$azure: #00F4F0;
$azure-dark: #1DC1C4;
$black: #212529;
$blue-dark: #002A32;
$light-red: #b92b27;
$light-blue: #1565C0;
$success: #17bb45;
$danger: #b92b27;

/* TYPOGRAPHY */
$font-light: 300;
$font-regular: 400;
$font-semibold: 600;
$font-bold: 700;

$font-size-small: .8rem;
$font-size-menu: 1rem;
$font-size-large: 1.3rem;
$font-size-extra-large: 3.5rem;
$font-size-hero-title: 5rem;

$font-title: 'Chakra Petch', sans-serif;
$font-body: 'League Spartan', sans-serif;