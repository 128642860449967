@mixin text {
  letter-spacing: .2px;
}

@mixin custom-control-white {
  background-color: rgba(255,255,255,0.1);
  border: solid 1px #fafafa;
  font-family: $font-body;
  color: $white;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-family: $font-body;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
    font-family: $font-body;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
    font-family: $font-body;
  }

  &:focus {
    background-color: rgba(255,255,255,0.35);
    color: $blue-dark;
    outline: none;
    border-color: $azure;
    box-shadow: none;
  }
}

@mixin custom-control-dark {
  font-family: $font-body;

  &:focus {
    color: $blue-dark;
    outline: none;
    border-color: $light-blue;
    box-shadow: none;
  }
}

@mixin gradient {
  background-image: linear-gradient(25deg, $light-red 0%, $light-blue 100%) !important;
}

@mixin gradient-inverse {
  background-image: linear-gradient(25deg, $light-blue 0%, $light-red 100%);
}

@mixin section {
  padding-top: 52px;
  padding-bottom: 52px;
}

@mixin hero {
  position: relative;
  padding-top: 66px;
  background-color: transparent;
  overflow: hidden;
  @include gradient;
  /*background-size: cover;
  background-position: left;
  background-repeat: no-repeat;*/

  /*@media only screen and (min-width: 992px) {
    background-image: url("../public/fighterodds.png"), linear-gradient(25deg, $blue-dark 0%, $azure-dark 100%);
  }*/
}

@mixin section-bg {
  @include gradient;
}

@mixin section-border {
  border-bottom: solid 2px $gray;
  border-top: solid 2px $gray;
  box-shadow: 0 0 12px $azure-dark;
}

@mixin hero-title {
  font-size: $font-size-extra-large;
  font-weight: $font-semibold;
  font-family: $font-title;

  @media only screen and (min-width: 600px) {
    font-size: $font-size-hero-title;
  }
}

@mixin title {
  font-size: $font-size-extra-large;
  font-family: $font-title;
  font-weight: $font-semibold;
}

@mixin subtitle {
  font-weight: $font-light;
}

@mixin text-shadow {

}

@mixin gradient-box {
  border-radius: 8px;
  padding: 16px 8px;
  box-shadow: 0 0 12px $azure-dark;
  border: solid 2px $azure;
  /*background: #360033;  !* fallback for old browsers *!
  background: -webkit-linear-gradient(90deg, $blue-dark 40%, $gray 100%);
  background: linear-gradient(90deg, $blue-dark 40%, $gray 100%);*/
}

@mixin price-table {
  font-family: $font-body;
  color: $blue-dark;

  thead {
    border: none !important;

    tr {
      border: none !important;
    }

    th {
      border: none !important;
    }
  }

  th:not(:first-child) {
    font-size: 2rem;
    color: $light-blue;
  }

  tbody {
    border-color: inherit !important;
    border-width: 1px !important;
  }

  .price {
    font-size: 2.8rem;
  }
}

@mixin white-box {
  background-color: $white;
  border-radius: 6px;
  padding: 32px;
  box-shadow: 0 0 8px rgba(0,0,0,0.1);
}

@mixin banner {
  img {
    opacity: .75;
    transition: all ease .225s;

    &:hover {
      opacity: 1;
    }
  }
}

@mixin stats-container {
  background-color: #1F1F1F;
  border-radius: 4px;

  .stats {
    box-shadow: inset -10px 0px 10px -10px rgba(0,0,0,0.4);
  }
}

@mixin stats-item {
  padding-bottom: 16px;
  margin-top: 16px;
  font-size: 1.15rem;
  border-bottom: dashed 3px #3A3A3AFF;

  &:last-child {
    border-bottom:0;
  }

  span {
    font-weight: 600;
  }
}

@mixin stats-count {
  color: $azure;
}