html {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.bg-gradient {
  @include gradient;
}

.bg-gray {
  background-color: $gray;
}

p,
body,
html,
input {
  font-family: $font-title;
  letter-spacing: 0.15px;
  color: $black;
}

label {
  font-family: $font-title !important;
  font-weight: 600;
  font-size: .9rem;

  @media (min-width: 768px) {
    letter-spacing: 0.5px;
  }
}

button {
  font-family: $font-title !important;
  letter-spacing: 0.5px;
}

a {
  text-decoration: none;
}

/* scrollbar width */
::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

/* scrollbar Track */
::-webkit-scrollbar-track {
  background: $gray;
}

/* scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: lighten($blue-dark, 5%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $azure-dark;
}

/* Chrome, Safari, Edge, Opera  remove arrows from number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.title {
  font-family: $font-title;
  letter-spacing: 1px;

  &-sm {
    font-size: 1rem;
  }

  &-md {
    font-size: 1.5rem;
  }

  &-lg {
    font-size: 1.8rem;
  }

  &-xl {
    font-size: 2.5rem;
  }

  &-secondary {
    color: $light-blue;
  }
}

.header {
  @include gradient;
  padding-top: 32px;
  padding-bottom: 32px;

  @media (min-width: 768px) {
    padding-bottom: 82px;
  }
}

.white {
  color: $white;
}

.custom_nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  padding: 0 8px;
  border-top: 1px solid $blue-dark;
  border-bottom: 0;
  background: $white;
  width: 100%;
  list-style: none;
  padding: 0 8px;
  margin: 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  z-index: 10;

  @media (min-width: 768px) {
    position: static;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    border-top: 0;
    border-bottom: 1px solid $gray;
    padding-bottom: 22px;
    font-family: $font-title;
    color: $blue-dark;
  }

  div {
    cursor: pointer;
    font-size: 1rem;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 8px;
    border-radius: 6px;

    @media (max-width: 768px) {
      font-size: .8rem;
      flex-grow: 1;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__active {
    background-color: $gray;
  }

}

.custom_container {
  width: 100%;
  min-height: 620px;
  margin: 0 auto;
  background-color: $white;
  padding: 16px;

  @media (min-width: 992px) {
    max-width: 1180px;
    border-radius: 6px;
    padding: 32px;
    margin-top: -62px;
  }
}

.custom_footer {
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 122px;

  @media (min-width: 992px) {
    max-width: 1180px;
    padding-bottom: 32px;
  }
}

.muted {
  color: $gray;
}

.close_btn {
  font-size: 2rem;
  width: 36px;
  width: 36px;
  text-align: center;
  padding: 6px;

  &:hover {
    opacity: .8;
    cursor:pointer;
  }
}

.toast-body {
  font-size: 1rem;
}

.fo_input {
  &__light {
    background-color: rgba(255,255,255,.2);
    border-color: $white;
    color: $white;

    &:focus {
      background-color: rgba(255, 255, 255, .3);
      color: $white;
      outline: 0;
      box-shadow: none;
      border-color: $blue-dark;
    }
  }
}